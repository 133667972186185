<template>
    <v-container class="ma-0 pa-0">
      <div class="section__text--title">Recientes</div>
      <v-row v-if="recentTopicsFiltered.length !== 0" dense flat class="mt-2">
        <v-col
          v-for="(card, index) in recentTopicsFiltered"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          class="mb-4"
          :class="{'pl-4': (index + 1) % 3 === 0 && !$vuetify.breakpoint.mobile, 'px-2': (index + 1) % 3 === 2 && !$vuetify.breakpoint.mobile, 'pr-4': (index + 1) % 3 === 1 && !$vuetify.breakpoint.mobile}"
          @click="$router.push({ name: 'FlashcardSimulator', params: { tipo: 'temas', ids: [card.id].join(',') } })"
        >
          <FlashcardItem
            :item="card"
            :havePending="false"
          />
        </v-col>
      </v-row>
      <h5 v-else class="text-center">No se encontraron flashcards recientes</h5>
    </v-container>
</template>
<script>
import FlashcardItem from '@/components/flashcard/FlashcardItem'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    FlashcardItem
  },
  computed: {
    ...mapState('flashcard', ['recentTopics', 'search']),
    recentTopicsFiltered () {
      return this.recentTopics.slice(0, 3)
    }
  },
  methods: {
    ...mapActions('flashcard', ['fetchRecentTopics'])
  },
  async created () {
    await this.fetchRecentTopics()
  }
}
</script>

<style>

</style>
