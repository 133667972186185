<template>
  <v-hover v-slot="{ hover }">
    <v-card class="d-flex flex-column justify-end align-start flashcard-item">
      <div class="card__content d-flex flex-column justify-space-between">
        <p class="flashcard__text--body" v-text="item.nombre"></p>
        <div>
          <p class="flashcard__text--subtitle ma-0" v-text="item.especialidad"></p>
          <div class="d-flex justify-space-between">
            <p p class="flashcard__text--subsubtitle ma-0 mt-1" v-text="item.area"></p>
            <span v-if="(hover && item.pendientes || $vuetify.breakpoint.mobile) && havePending" class="my-auto card__detail d-flex justify-center align-center">
              <p class="card__subtext ma-0">{{ item.pendientes }}</p>
            </span>
          </div>
        </div>
      </div>
      <div class="footer__line"></div>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'FlashcardItem',
  props: {
    havePending: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: function () {
        return {
          area: '',
          especialidad: '',
          id: null,
          nombre: ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.padd {
  padding-left: 14px;
}
.card {
  &__content {
    padding: 30px;
    width: 100%;
    height: 100% !important;
  }
  &__subtext {
    font-size: 14px;
    line-height: 21px;
  }
  &__detail {
    width: 64px;
    height: 19px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 3px;
  }
}
</style>
