<template>
  <v-container style="background: #EFF3F4" class="d-flex justify-center">
    <v-col cols="12" md="11">
      <div style="width: 104%; margin-left: -2% !important;" class="ma-0 pa-0">
        <v-slide-y-transition hide-on-leave>
          <FlashcardsList
            v-if="!loading"
            :height="$vuetify.breakpoint.mobile ? 470: 410"
            :activeId="activeId"
            :flashcards="pendingFlashcards"
            @selectState="selectState"
          />
        </v-slide-y-transition>
        <div v-if="loading" class="progress__wrapper d-flex justify-center my-15">
          <v-progress-circular indeterminate/>
        </div>
      </div>
      <FlashcardLevelBar
        :progress="progress"
        :level="nivel"
      />
      <PendingCard class="mb-5"/>
      <RecentCard/>
    </v-col>
  </v-container>
</template>

<script>
import FlashcardsList from '@/components/flashcard/FlashcardsList.vue'
import FlashcardLevelBar from '@/components/flashcard/FlashcardLevelBar.vue'
import PendingCard from '@/components/PendingCard.vue'
import RecentCard from '@/components/RecentCard.vue'
// import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { mapState, mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'Inicio',

  components: {
    FlashcardsList,
    FlashcardLevelBar,
    PendingCard,
    RecentCard
  },

  data: () => ({
    activeId: 0,
    levelBar: 0,
    offset: 20,
    level: 1,
    loading: false,
    flashcardsSummary: null
  }),
  computed: {
    ...mapState('flashcard', ['pendingFlashcards']),
    ...mapState('profile', ['user']),
    ...mapGetters('profile', ['getExperience']),
    progress () {
      if (!this.flashcardsSummary) { return 0 }
      const percentage = this.flashcardsSummary.cantidad_flashcards_aprendidas / this.flashcardsSummary.cantidad_flashcards_total * 100
      return percentage.toFixed(2)
    },
    flashcardIndex () {
      return this.pendingFlashcards[this.activeId].id
    },
    nivel () {
      return `${this.progress}% Aprendido`
    }
  },
  methods: {
    ...mapActions('profile', ['fetchUser']),
    // ...mapMutations('flashcard', ['SET_EXPERIENCE']),
    ...mapActions('flashcard', ['fetchPendingFlashcards']),
    async selectState (state) {
      try {
        await axios.post(`api/flashcards/flashcards/${this.flashcardIndex}/responder/`, { respuesta: state })
        this.levelBar += 1
        this.activeId += 1
        this.adjustPercentage(state)
        if (this.levelBar === 20) {
          this.levelBar = 0
          await this.fetchUser()
          await this.assignLvl()
        }
        if (this.pendingFlashcards.length - 1 < this.activeId) {
          const confirm = this.$root.$confirm({
            message: 'Ups! se terminaron las flashcards',
            acceptText: 'Ok'
          })
          if (confirm) {
            this.$router.push({ name: 'UnitsAreas' })
          }
          this.activeId = 0
        }
      } catch (e) {
        console.log(e)
      }
    },
    adjustPercentage (state) {
      if (!this.flashcardsSummary || state === 'DIFICIL' || state === 'NO_LA_SE') { return }
      this.flashcardsSummary.cantidad_flashcards_aprendidas += 1
    },
    assignLvl () {
      this.level = parseInt(this.getExperience / 20)
      this.levelBar = this.getExperience % 20
    },
    async fetchMaterizedFlashcardsPercent () {
      const response = await axios.post('api/flashcards/flashcards/resumen_pendientes/')
      this.flashcardsSummary = response.data
    }
  },
  async mounted () {
    this.loading = true
    await this.fetchUser()
    await this.fetchPendingFlashcards()
    await this.fetchMaterizedFlashcardsPercent()
    await this.assignLvl()
    this.loading = false
  }
}
</script>
