<template>
<div :class="isMobile ? 'mt-0': 'ma-0 mt-3'">
  <!-- <p class="section__text--title">Flashcard General</p> -->
  <v-sheet
    v-bind="$attrs"
    class="flashcard-general d-flex justify-center align-center transparent"
    flat
  >
    <div class="flashcard-general__inner" :class="flipped ? 'is-flipped': 'flashcard-general__inner'">
      <div @click="toggleCard" class="d-flex align-center flashcard-general__face flashcard-general__face--front">
        <div class="flashcard-general__favourite-wrapper" @click.prevent.stop>
          <v-card
            v-if="$route.params.tipo!=='mis-flashcards'"
            class="overflow-hidden d-flex justify-center align-center flashcard-general__favourite"
            height="40"
            width="40"
            @click.prevent="doFavourite"
          >
            <v-img
              :src="isFavourite ? require('@/assets/img/icon/star-filled.svg'): require('@/assets/img/icon/star.svg')"
              max-width="24"
              height="24"
            />
          </v-card>
        </div>
        <v-img
          :src="require('@/assets/logo-usa.png')"
          class="flashcard-background"
          style="position: absolute; left: 20%; right: 20%"
          contain
        >
        </v-img>
        <div class="d-flex align-center justify-center fill-height flex-grow-1 border" style="z-index: 1; width: 100%; " :class="isMobile ? 'px-10 flex-column mt-10': 'px-15'">
          <p class="flashcard__text--front text-center avoid_selection" :class="{'simulator': $route.name === 'FlashcardSimulator'}">
            {{ card.enunciado }}
          </p>
        </div>
      </div>
      <div
        class="d-flex align-center flashcard-general__face flashcard-general__face--back"
        :class="{'test': flipped}"
        style="z-index: 2 !important;"
        @click="toggleCard"
      >

        <div
          v-if="card.comentario || card.imagen"
          style="position: absolute; top: 15px; right: 5px; z-index: 3;"
          @click.prevent.stop
        >
          <v-btn
            color="#0583DA"
            class="d-flex justify-center align-center showmore__wrapper"
            text
            @click.prevent="changeState()"
          >
            <p class="pa-0 ma-0 showmore">
              Ver más
            </p>
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </div>
        <v-img
          :src="require('@/assets/logo-usa.png')"
          class="flashcard-background"
          style="position: absolute; left: 20%; right: 20%; z-index: -1 !important;"
          contain
        >
        </v-img>
        <div
          class="d-flex flex-column align-center justify-center fill-height fill-width"
          :class="isMobile ? 'py-2': 'px-10'"
        >
          <v-scroll-x-transition hide-on-leave>
            <template v-if="isActive">
              <div v-if="isRichText(card.respuesta)" :class="isMobile ? 'mx-10 ': 'ma-auto'" v-html="card.respuesta">
              </div>
              <div v-else
                class="flashcard__text--back text-center scrolling avoid_selection" style="width: 80%;"
                :class="isMobile ? 'mx-10 ': 'ma-auto'"
              >
                {{ card.respuesta }}
              </div>
            </template>
          </v-scroll-x-transition>
          <v-slide-x-transition leave-absolute>
            <div v-if="!isActive" class="flashcard-general__content d-flex align-center justify-space-between"
              :class="{'flex-column': isMobile}"
            >
              <!-- <v-img
                v-if="card.imagen"
                :src="card.imagen"
                class="flashcard-general__img"
                :class="!isMobile ? 'ma-0': 'my-auto mt-10'"
              /> -->
              <FlashcardImageViewer
                v-if="card.imagen"
                :imageSrc="card.imagen || 'https://picsum.photos/700/400?random'"
              />
              <p v-if="card.comentario" class="avoid_selection flashcard__comment flex-grow-1 text-justify scrolling scrolling__question w-100 d-flex justify-center align-center"
                :class="isSmAndDown ? 'mx-10 text-center': 'px-15'"
              >{{ card.comentario }}</p>
            </div>
          </v-slide-x-transition>
        </div>
        <v-slide-x-transition>
          <div
            v-if="isActive"
            class="d-flex align-center justify-space-around span__wraper"
          >
            <FlashcardFeedback
              :labels='labels'
              @selectState="selectState"
            />
          </div>
        </v-slide-x-transition>
      </div>
    </div>
  </v-sheet>
</div>
</template>

<script>
import vuetifyHelpers from '@/mixins/vuetifyHelpers'
import FlashcardFeedback from '@/components/flashcard/FlashcardFeedback'
import FlashcardImageViewer from '@/components/flashcard/FlashcardImageViewer'
import axios from 'axios'

export default {
  mixins: [
    vuetifyHelpers
  ],
  components: {
    FlashcardImageViewer,
    FlashcardFeedback
  },
  data () {
    return {
      isActive: true,
      labels: ['Fácil', 'Normal', 'Difícil', 'No lo sé'],
      flashcards: [],
      state: false,
      flipped: false,
      activeState: '',
      isFavourite: false
    }
  },
  props: {
    card: {
      type: Object,
      default: function () {
        return {
          area: '',
          comentario: '',
          enunciado: '',
          especialidad: '',
          fase: '',
          id: null,
          imagen: null,
          respuesta: '',
          tags: [],
          tema: '',
          es_favorito: false
        }
      }
    }
  },
  methods: {
    toggleCard () {
      this.flipped = !this.flipped
    },
    selectState (state) {
      this.$emit('selectState', state)
    },
    changeState () {
      this.isActive = !this.isActive
    },
    async doFavourite () {
      try {
        const response = await axios.post(`api/flashcards/flashcards/${this.card.id}/toggle_favorito/`)
        this.isFavourite = response.data.es_favorito
      } catch (e) {
        this.$root.$confirm({
          title: 'Ups!',
          message: e.response,
          acceptMessage: 'Ok'
        })
      }
    },
    isRichText (text) {
      const patronHTML = /<[^>]*>/
      const coincidencia = text.match(patronHTML)
      return !!coincidencia
    }
  },
  mounted () {
    this.isFavourite = this.card.es_favorito
  }
}
</script>
<style lang="scss" scoped>
.simulator {
  font-size: 24px !important;
  line-height: 36px !important;
}
.h-70 {
  height: 70% !important;
}
.flashcard-general {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  height: 369px;
  cursor: pointer;
  perspective: 3500px;
  backface-visibility: hidden !important;
  transition: transform 0.2s !important;
  width: 100%;
  &__favourite {
    background: #FFFFFF !important;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
  }
  &__favourite-wrapper {
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 999;
  }
  // transform-style: preserve-3d !important;
  &__img {
    height: 223px !important;
    max-width: 223px;
  }
  &__content {
    width: 85%;
    height: 223px;
  }
}

.slide-fade-enter-active {
  //  transition: all .3s ease;
}

.scrolling {
  max-height: calc(90vh - 101px);
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  &__question {
    height: 190px;
  }
}
.scrolling::-webkit-scrollbar {
  display: none;
}

.flashcard-general__inner {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flashcard-general__inner.is-flipped {
  transform: rotateY(-180deg) !important;
}

.flashcard-general__face {
  position: absolute !important;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden !important;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0px 3px 18px 3px rgba(0, 0, 0, 0.2);
  font-weight: 600;

  &--front {
    background: #FFFFFF;
    color: #262626;
  }
  &--back {
    background: #FFFFFF;
    transform: rotateY(180deg)translateZ(1px);
  }
}
.span__wraper {
  // box-sizing: border-box;
  position: absolute;
  border-radius: 10px;
  bottom: 30px;
  left: 10%;
  right: 10%;
  width: 80%;
  height: 10% !important;
  z-index: 9998!important;
}
.span__footer {
  border-radius: 10px;
  width: 300px;
  height: 100%;
  margin: 0 3px;
  &--easy {
    border: 1px solid #1FC1D0;
    z-index: 10000!important;
  }
  &--normal {
    border: 1px solid #0583DA;
  }
  &--hard {
    border: 1px solid #DA0505;
  }
  &--nose {
    border: 1px solid #DA0505;
  }
}
.span__text {
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}

.showmore {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0583DA;
  &__wrapper {
    border-radius: 10px;
    text-transform: none;
  }
}

.flashcard-background {
  opacity: 0.275;
}

@media (max-width: 1260px) {
  .showmore {
    font-size: 14px;
    line-height: 21px;
    &__wrapper {
      background: #FFFFFF;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    }
  }
  .tm {
    top: 5px !important;
  }
  .simulator {
    font-size: 14px !important;
    line-height: 21px !important;
  }
  .flashcard-general {
    height: 428px !important;
    &__img {
      max-height: 162px !important;
      width: 162px !important;
    }
    &__content {
      height: 389px;
    }
  }
  .span__footer {
    width: 78px;
  }
  .span__wraper {
    left: 10%;
    width: 80%;
    height: 28px;
  }
  .span__text {
    font-size: 12px;
    line-height: 18px;
  }
  .scrolling {
    &__question {
      height: 163px;
    }
  }
}

</style>
