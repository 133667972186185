<template>
  <div class="d-flex justify-center" :class="{'flex-wrap mb-10': $vuetify.breakpoint.width < 400 }" style="height: 35px;">
    <v-card
      text
      v-for="(label, index) in labels"
      :key="index"
      @click.prevent="selectState(stateFlashcard(label))"
      class="span__footer d-flex justify-center mb-1"
      :class="{ 'flex-shrink': $vuetify.breakpoint.width > 400, 'span__footer--easy': label==='Fácil', 'span__footer--normal': label==='Normal', 'span__footer--hard': label==='Difícil', 'span__footer--nose': label==='No lo sé' }"
      style="text-transform: none !important;"
    >
      <p class="span__text my-auto avoid_selection">
        {{ label }}
      </p>
    </v-card>
  </div>
</template>

<script>
import flashcard from '@/mixins/flashcard'

export default {
  mixins: [flashcard],
  name: 'FlashcardFeedback',
  props: {
    labels: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    selectState (state) {
      this.$emit('selectState', state)
    }
  }
}
</script>

<style lang="scss" scoped>
.span__footer {
  border-radius: 10px;
  width: 128px;
  height: 100%;
  margin: 0 3px;
  &--easy {
    background: linear-gradient(90deg, #0583DA 0%, #7BB6DF 100%);
    z-index: 10000!important;
  }
  &--normal {
    background: linear-gradient(90deg, #FFA31A 0%, #FFD18B 100%);
  }
  &--hard {
    background: linear-gradient(90deg, #DA0505 0%, #F77272 100%);
  }
  &--nose {
    background: linear-gradient(90deg, #1FC1D0 0%, #9AE7EE 100%);
  }
}
.span__text {
  font-size: 16px;
  line-height: 24px;
  color: #FFFF;
}
@media (max-width: 960px) {
  .span__footer {
    width: 78px;
  }
  .span__text {
    font-size: 12px;
    line-height: 18px;
  }
}
</style>
