<template>
  <v-carousel
    v-bind="$attrs"
    v-on="$listeners"
    fluid
    hide-delimiters
    :show-arrows="false"
    light
    touchless
    v-model="activeId"
    class="ma-0 pa-0"
  >
    <v-carousel-item
      v-for="(card, index) in flashcards"
      :key="index"
      class="ma-0 pa-0"
    >
      <Flashcard
        :height="flashcardHeight"
        style="width: 96% !important;"
        class="m-5 mx-auto"
        :class="{'py-5': $vuetify.breakpoint.mobile}"
        :card="card"
        @selectState="selectState"
      />
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import Flashcard from '@/components/flashcard/Flashcard.vue'

export default {
  inheritAttrs: false,
  name: 'FlashcardsList',
  components: {
    Flashcard
  },
  props: {
    flashcards: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      activeId: 0
    }
  },
  computed: {
    flashcardHeight () {
      if (this.$route.name === 'Inicio') {
        return 369
      } else {
        return 412
      }
    }
  },
  methods: {
    selectState (state) {
      this.$emit('selectState', state)
      this.activeId += 1
    }
  }
}
</script>
