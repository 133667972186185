<template>
  <v-container class="ma-0 pa-0 mt-3">
    <div class="section__text--title d-flex justify-space-between align-center mb-2" :class="{'mt-5': pendingTopics.length <= 3}">
      Pendientes
      <v-btn v-if="pendingTopics.length > 3" class="btn ma-0 pa-0 pl-2" exact text @click="showMore = !showMore">
        <p class="btn__text ma-auto">Ver todas</p>
        <v-icon color="primary">mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <transition-group
      name="list"
      mode="out-in"
      tag="v-row"
      class=""
      v-if="pendingTopicFiltered.length !== 0"
    >
      <v-col
        v-for="(card, index) in pendingTopicFiltered"
        :key="card.id"
        class="mb-0 flashcard-col list-item"
        :class="{'pl-4': (index + 1) % 3 === 0 && !$vuetify.breakpoint.mobile, 'px-2': (index + 1) % 3 === 2 && !$vuetify.breakpoint.mobile, 'pr-4': (index + 1) % 3 === 1 && !$vuetify.breakpoint.mobile}"
        cols="12"
        sm="6"
        md="4"
        @click="$router.push({ name: 'FlashcardSimulator', params: { tipo: 'temas', ids: [card.id].join(',') } })"
      >
          <FlashcardItem
            :item="card"
            :havePending="true"
          />
      </v-col>
    </transition-group>
    <h5 v-else class="text-center">No se encontraron flashcards pendientes</h5>
  </v-container>
</template>
<script>
import FlashcardItem from '@/components/flashcard/FlashcardItem'
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      showMore: false
    }
  },
  components: {
    FlashcardItem
  },
  computed: {
    ...mapState('flashcard', ['pendingTopics', 'search']),
    pendingTopicFiltered () {
      if (this.showMore) {
        return this.pendingTopics
      } else {
        return this.pendingTopics.slice(0, 3)
      }
    }
  },
  methods: {
    ...mapActions('flashcard', ['fetchPendingTopics'])
  },
  async created () {
    await this.fetchPendingTopics()
  }
}
</script>

<style lang="scss" scoped>
.btn {
  &__text {
    text-transform: none !important;
    font-weight: 600;
    color: #0583DA;
  }
}
.list-item {
  display: inline-block;
  margin-right: 0;
}
.list-enter-active, .list-leave-active {
  transition: all 0.3s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
